//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import qs from 'qs';

export default {
  data() {
    return {
      success: false,
      firstName: '',
      lastName: '',
      company: '',
      phone: '',
      email: '',
      subject: '',
      message: ''
    };
  },
  computed: {
    url() {
      if (typeof window !== 'undefined') {
        return window?.location.href ?? 'https://conversr.com';
      }

      return (
        (process.env.SITE_PATH || 'https://conversr.com') +
        this.$nuxt.$route.path
      );
    },
    subjectLine() {
      return `Conversr Lead: ${this.firstName}`;
    }
  },
  methods: {
    async checkRecaptcha() {
      const self = this;
      try {
        const token = await this.$recaptcha.execute('contact_conversr');

        this.$axios({
          url: '/.netlify/functions/captcha',
          baseURL: '/',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: 0
          },
          data: qs.stringify({
            token
          })
        }).then(async function(response) {
          if (response?.data?.success && response?.data?.score > 0.5) {
            await self.recordZapier();
            this.success = true;
            // window.location.assign('/?success=thank-you');
          } else {
            console.error('RECAPTCHA_FAIL');
          }
        });

        await this.$recaptcha.reset();
      } catch (error) {
        console.error('Recaptcha error:', error);
      }
    },
    submitForm() {
      if (this.$refs.form.reportValidity() && this.$refs.form.checkValidity()) {
        this.checkRecaptcha();
      } else {
        console.error('Form Invalid');
      }
    },
    async recordZapier() {
      const data = {
        name: this.firstName + ' ' + this.lastName,
        company: this.company,
        phone: this.phone,
        email: this.email,
        subject: this.subject,
        message: this.message,
        source: this.url || window?.location.href || 'https://conversr.com'
      };

      console.log('Zapier Data:', data);

      const response = await this.$axios(process.env.FORM_ZAP_CONTACT_URL, {
        method: 'POST',
        data: JSON.stringify(data)
      });

      console.log('Zapier Response:', response);

      if (response.data) {
        this.success = true;
        return response.data;
      } else {
        const error = new Error('ZAP response not found');
        error.status = 404;
        throw error;
      }
    }
  },
  async mounted() {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      console.error(e);
    }
  }
};
