//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';
import SectionTemplate from './SectionTemplate.vue';
import * as Forms from '~/components/forms'

export default {
  extends: SectionTemplate,
  data() {
    return {
      success: false,
      maintenance: false
    }
  },
  components: {
    ...Forms
  },
  computed: {
    _getTemplate() {
      if (!this.data.form) return false;
      return 'Form' + this.data.form;
    },
    currentUrl(){
      if (window) return window.location.href;
      return "";
    }
  },
  created() {
    if (process.browser) {
      const self = this;

      if (process.env) {
        this.maintenance = !!process.env.MAINTENANCE
      }

      if (this.$route.hash === '#success') {
        this.success = true
      }

      $(function () {

        const urlHash = self.$route.hash || location.hash;
        const hashElement = $(urlHash);

        if (hashElement.length > 0) {
          $('html, body').animate({
            scrollTop: hashElement.offset().top
          }, 500);
        }

        $('[data-section="form-request"] form').on('submit', function (event) {
          if (this.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }

          $(this).addClass('was-validated');
        })
      });
    }
  }
}
